// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-commercial-js": () => import("./../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-consumer-unit-js": () => import("./../src/pages/consumer-unit.js" /* webpackChunkName: "component---src-pages-consumer-unit-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-domestic-rewires-js": () => import("./../src/pages/domestic-rewires.js" /* webpackChunkName: "component---src-pages-domestic-rewires-js" */),
  "component---src-pages-eic-rs-js": () => import("./../src/pages/EICRs.js" /* webpackChunkName: "component---src-pages-eic-rs-js" */),
  "component---src-pages-electrical-services-pembrokeshire-js": () => import("./../src/pages/electrical-services-pembrokeshire.js" /* webpackChunkName: "component---src-pages-electrical-services-pembrokeshire-js" */),
  "component---src-pages-fault-finding-js": () => import("./../src/pages/fault-finding.js" /* webpackChunkName: "component---src-pages-fault-finding-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-builds-js": () => import("./../src/pages/new-builds.js" /* webpackChunkName: "component---src-pages-new-builds-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

